<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import VInfoBanner from "~/components/VInfoBanner.vue";
import BellIcon from "~/assets/svg/icons/bell-ring.svg";
import { useUserStore } from "~/store/user";
import { usePushStore } from "~/store/push";
import { useModalsStore, MODALS } from "~/store/modals";
import { usePlatformStore } from "~/store/platform";

const userStore = useUserStore();
const pushStore = usePushStore();
const modalsStore = useModalsStore();
const platformStore = usePlatformStore();
const route = useRoute();

const emits = defineEmits<{ click: []; mounted: []; }>();

const showBanner = computed(
  () =>
    route.path !== Routes.Profile &&
    !platformStore.platformInfo.oldIosVersion &&
    (!userStore.permissions.isPushPermitted || !pushStore.hasToken),
);

const notificationClick = () => {
  emits("click");
  modalsStore.close(MODALS.PwaIosInstruction);
};
onMounted(() => {
  emits("mounted");
});
</script>

<template>
  <VInfoBanner
    v-if="showBanner"
    :icon="BellIcon"
    :to="Routes.Profile"
    text="Получайте уведомления о выгодных акциях"
    @click="notificationClick()"
  />
</template>
