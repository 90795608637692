import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import type { Messaging } from "firebase/messaging";
import { defineStore } from "pinia";
import { ref, reactive } from "vue";
import { useLocalStorage } from "@vueuse/core";
import { HttpCodes, urls } from "~/api/config";
import { useTransport } from "~/composables/useTransport";
import { useUserStore } from "~/store/user";
import { storage } from "~/utils/consts";
import useNotification from "~/composables/useNotification";
import useAnalytics from "~/composables/useAnalytics";
import serviceWorkerInit from "~/sw";

interface IPushStatus {
  sendToken: "initial" | "pending" | "success" | "error";
  permissions: "initial" | "pending" | "success" | "error";
}

export const fbaseConfig = {
  apiKey: "AIzaSyA8MZ5828RVcFW2p9t_EBRhmIeyUkn5JuQ",
  authDomain: "magnitandroid-2c90e.firebaseapp.com",
  databaseURL: "https://magnitandroid-2c90e.firebaseio.com",
  projectId: "magnitandroid-2c90e",
  storageBucket: "magnitandroid-2c90e.appspot.com",
  messagingSenderId: "491612239134",
  appId: "1:491612239134:web:0d2bff77d5d0654be3450f",
  measurementId: "G-546WDFVLKF",
};

const VAPID_KEY =
  "BPDAwhzHAmkzDS4Z-xsisxkn71-91BxVLiPQDfsd00JUlfx-bVqPzxl0zvmnhXcsaN97MgdXDE48FNDvnoZ9ZWQ";

export const usePushStore = defineStore("push", () => {
  const userStore = useUserStore();
  const { send } = useAnalytics();
  const { warning } = useNotification();

  const fbm = ref<Messaging>();
  const status = reactive<IPushStatus>({
    sendToken: "initial",
    permissions: "initial",
  });

  const savedSubscription = useLocalStorage<string>(
    storage.pushSubscription,
    "",
  );
  const lastPermissionsState = useLocalStorage(
    storage.lastPushPermissionState,
    "",
  );

  watch(
    () => userStore.permissions.isPushPermitted,
    (next) => {
      if (userStore.status.permissions === "success") {
        if (next) {
          getFBMApi();
        }
      }
    },
  );

  async function enrollDevice(token: string, hasPermission: boolean) {
    status.sendToken = "pending";

    const { data, error } = await useTransport(urls.user.enrollDevice, {
      method: "POST",
      body: {
        pushToken: token,
        notificationsPermitted: hasPermission,
      },
      permissions: { jwt: true },
    });

    if (data.value !== null) {
      status.sendToken = "success";
      if (hasPermission) {
        savedSubscription.value = token;
        await userStore.updatePermissions(true, "isPushPermitted");
      }
    }

    if (error.value) {
      if (
        error.value?.statusCode &&
        error.value.statusCode > HttpCodes.Error4xx
      ) {
        status.sendToken = "error";

        warning({
          text: "Не удалось включить уведомления. Попробуйте ещё раз",
        });
      }
    }
  }

  const getFBMApi = (): Messaging => {
    if (fbm.value) {
      return fbm.value;
    }
    const fb = initializeApp(fbaseConfig);
    fbm.value = getMessaging(fb);
    return fbm.value;
  };

  const requestPermission = async (auth?: boolean) => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        send(
          auth
            ? "App:Host:Auth:Push:Permission:Check:Granted"
            : "App:Host:Profile:Push:Permission:Check:Granted",
        );
        const registration = await navigator.serviceWorker.getRegistration();
        if (!registration) {
          await serviceWorkerInit();
        }
        const api = getFBMApi();
        status.permissions = "success";

        const currentToken = await getToken(api, {
          serviceWorkerRegistration: registration,
          vapidKey: VAPID_KEY,
        });

        if (currentToken) {
          try {
            await enrollDevice(currentToken, true);
            lastPermissionsState.value = permission;
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log("An error occurred while retrieving token. ", err);
          }
        } else {
          // Show permission request.
          // eslint-disable-next-line no-console
          console.log(
            "No registration token available. Request permission to generate one.",
          );
        }
      } else {
        send(
          auth
            ? "App:Host:Auth:Push:Permission:Check:Declined"
            : "App:Host:Profile:Push:Permission:Check:Declined",
        );
      }
    } catch (e) {
      status.permissions = "error";
      send(
        auth ? "App:Auth:Push:Failure:View" : "App:Profile:Push:Failure:View",
      );
      warning({
        text: "Не удалось включить уведомления. Попробуйте ещё раз",
      });
    }
  };

  return {
    status,
    hasToken: computed(() => Boolean(savedSubscription.value)),
    requestPermission,
    enrollDevice,
  };
});
