const serviceWorkerInit = async () => {
  if (typeof navigator === "undefined") {
    return;
  }
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js",
      );
      if (registration) {
        // eslint-disable-next-line no-console
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope,
        );
        await registration.update();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("ServiceWorker registration failed: ", err);
    }
  }
};

export default serviceWorkerInit;
